"use client";

import type { SwiperClass } from "swiper/react";
import { useEffect, useMemo, useRef, useState } from "react";
import Image from "next/image";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";
import { cn } from "@/utils/tw";
import { EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import { useOnScreen } from "@/hooks/useOnScreen";
type TestimonialItem = {
  image: string;
  content: React.ReactNode;
};
export const Testimonials = ({
  items,
  description
}: {
  items: TestimonialItem[];
  description: string;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const slides = useMemo(() => items.map((el, i) => ({
    id: `${i}`,
    renderItem: <div className="relative flex h-full items-end overflow-hidden">
            <div className="absolute z-10 h-full w-full bg-gradient-to-t from-purple to-transparent opacity-90" />
            <Image fill alt="" src={el.image} className="object-cover" />
            <div className="z-10 mb-4 w-full space-y-2 p-8">{el.content}</div>
          </div>
  })), [items]);
  const swiper = useRef<SwiperClass>();
  const [activeItem, setActiveItem] = useState(0);
  const [timer, setTimer] = useState(0);
  const isInView = useRef(false);
  const isOnScreen = useOnScreen(ref);
  useEffect(() => {
    isInView.current = isOnScreen;
  }, [isOnScreen]);
  useEffect(() => {
    setTimer(0);
  }, [activeItem]);
  useEffect(() => {
    if (timer > 360) {
      swiper.current?.slideNext(100);
      setTimer(0);
    }
  }, [timer]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isInView.current) return;
      setTimer(prev => {
        return prev + 1;
      });
    }, 20);
    return () => clearInterval(interval);
  }, [isInView]);
  const buttons = items.map((_, index) => <div key={index} className={cn("relative h-12 w-12 shrink-0 overflow-hidden bg-transparent p-1 transition-all", {
    "rounded-full": activeItem === index
  })}>
      <div className="absolute left-0 top-0 h-12 w-12 rounded-full" style={{
      background: activeItem === index ? `conic-gradient(from 180deg, rgba(117, 40, 178, 1) ${timer}deg, rgba(117, 40, 178, 0) 0deg)` : "transparent"
    }} />
      <Button size="lg" className={cn("relative h-full w-full transition-all", {
      "bg-yellow-2 text-purple": activeItem === index,
      "bg-purple-2 text-purple-4": activeItem !== index
    })} onClick={() => swiper.current?.slideToLoop(index, 100)} rounded={activeItem === index}>
        {index + 1}
      </Button>
    </div>);
  return <div className="relative overflow-hidden bg-purple" ref={ref} data-sentry-component="Testimonials" data-sentry-source-file="Testimonials.tsx">
      <div className="container relative flex w-full items-center gap-16 py-20 max-lg:flex-col">
        <div className="space-y-8">
          <Text.H4 className="max-w-sm text-yellow-2" data-sentry-element="unknown" data-sentry-source-file="Testimonials.tsx">What people are saying</Text.H4>
          <Text.P className="max-w-sm text-beige" data-sentry-element="unknown" data-sentry-source-file="Testimonials.tsx">{description}</Text.P>
          <div className="flex gap-2 max-lg:hidden">{buttons}</div>
        </div>
        <div className="relative flex h-[600px] max-w-full flex-1 items-center justify-center max-lg:w-full">
          <Button variant="ghost" className="text-beige max-lg:hidden" size="lg" onClick={() => swiper.current?.slidePrev(100)} data-sentry-element="Button" data-sentry-source-file="Testimonials.tsx">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none" data-sentry-element="svg" data-sentry-source-file="Testimonials.tsx">
              <path opacity="0.6" d="M14.4197 27C12.8804 25.3615 1 14.8667 1 14.8667C1 14.8667 9.73502 4.602 12.9198 1" stroke="#EEDECC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Testimonials.tsx" />
            </svg>
          </Button>
          <div className="relative overflow-hidden rounded-[40px] p-1 max-lg:w-full lg:h-full lg:rounded-[90px]" style={{
          background: `conic-gradient(from 180deg, rgba(117, 40, 178, 1) ${timer}deg, rgba(117, 40, 178, 0) 0deg)`
        }}>
            <Swiper modules={[EffectFade]} loop onSwiper={ref => swiper.current = ref} onSlideChange={({
            realIndex
          }) => setActiveItem(realIndex)} className="relative m-0 aspect-[4/5] rounded-[40px] max-lg:w-full lg:h-full lg:rounded-[90px]" effect="fade" fadeEffect={{
            crossFade: true
          }} data-sentry-element="Swiper" data-sentry-source-file="Testimonials.tsx">
              {slides.map(item => <SwiperSlide key={item.id}>{item.renderItem}</SwiperSlide>)}
            </Swiper>
          </div>
          <Button variant="ghost" className="text-beige max-lg:hidden" size="lg" onClick={() => swiper.current?.slideNext(100)} data-sentry-element="Button" data-sentry-source-file="Testimonials.tsx">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none" data-sentry-element="svg" data-sentry-source-file="Testimonials.tsx">
              <path opacity="0.6" d="M1.42019 0.999999C2.95944 2.63852 14.8398 13.1333 14.8398 13.1333C14.8398 13.1333 6.10483 23.398 2.92009 27" stroke="#EEDECC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Testimonials.tsx" />
            </svg>
          </Button>
        </div>
        <div className="flex max-w-full flex-row gap-2 overflow-auto lg:hidden">{buttons}</div>
      </div>
    </div>;
};