"use client";

import { useState } from "react";
import { Button } from "@/components/ui/Button";
import { FormField } from "@/components/ui/Form";
import { Input } from "@/components/ui/Input";
import { Text } from "@/components/ui/Text";
import { useToast } from "@/components/ui/use-toast";
import { defaultFormErrorToast } from "@/utils/formErrorToast";
import { trpc } from "@/utils/TrpcProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, useForm } from "react-hook-form";
import { z } from "zod";
import { useLocaleProvider } from "../../LocaleProvider";
const formSchema = z.object({
  email: z.string().email()
});
export const Newsletter = () => {
  const [subscriptions, setSubscriptions] = useState<("newsletter" | "product" | "newListings")[]>(["newListings", "product", "newsletter"]);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onChange"
  });
  const {
    country
  } = useLocaleProvider();
  const {
    toast
  } = useToast();
  const signUpNewsletter = trpc.misc.registerNewsletter.useMutation({
    onSuccess() {
      toast({
        title: "Thanks for signing up to our newsletter!"
      });
      form.reset();
    },
    onError: er => {
      toast({
        title: er.message,
        variant: "destructive"
      });
    }
  });
  const hasNewListings = subscriptions.includes("newListings");
  const hasProduct = subscriptions.includes("product");
  const hasNewsletter = subscriptions.includes("newsletter");
  const isEverythingChecked = hasNewListings && hasProduct && hasNewsletter;
  return <Form control={form.control} data-sentry-element="Form" data-sentry-component="Newsletter" data-sentry-source-file="Newsletter.tsx">
      <div className="bg-purple py-20 lg:py-36" id="newsletter">
        <div className="container relative">
          <Text.H4 className="max-w-lg text-beige-2" data-sentry-element="unknown" data-sentry-source-file="Newsletter.tsx">
            Get weekly tips, updates, and new listings.
          </Text.H4>
          <Text.P className="mt-16 mt-8 text-beige-2" data-sentry-element="unknown" data-sentry-source-file="Newsletter.tsx">I am interested in:</Text.P>
          <div className="mt-6 flex flex-wrap gap-2">
            <Button variant={isEverythingChecked ? "purple-lighter" : "ghost"} className="text-white" icon={isEverythingChecked ? "check-circle" : undefined} iconPlacement="left" onClick={() => {
            if (isEverythingChecked) {
              setSubscriptions(["newListings"]);
            } else {
              setSubscriptions(["newListings", "product", "newsletter"]);
            }
          }} data-sentry-element="Button" data-sentry-source-file="Newsletter.tsx">
              Everything
            </Button>
            <Button variant={hasNewListings && !isEverythingChecked ? "purple-lighter" : "ghost"} className="text-white" icon={hasNewListings && !isEverythingChecked ? "check-circle" : undefined} iconPlacement="left" onClick={() => {
            if (isEverythingChecked) {
              setSubscriptions(["newListings"]);
            } else if (hasNewListings) {
              setSubscriptions(subscriptions.filter(s => s !== "newListings"));
            } else {
              setSubscriptions([...subscriptions, "newListings"]);
            }
          }} data-sentry-element="Button" data-sentry-source-file="Newsletter.tsx">
              New Listings
            </Button>
            <Button variant={hasProduct && !isEverythingChecked ? "purple-lighter" : "ghost"} className="text-white" icon={hasProduct && !isEverythingChecked ? "check-circle" : undefined} iconPlacement="left" onClick={() => {
            if (isEverythingChecked) {
              setSubscriptions(["product"]);
            } else if (hasProduct) {
              setSubscriptions(subscriptions.filter(s => s !== "product"));
            } else {
              setSubscriptions([...subscriptions, "product"]);
            }
          }} data-sentry-element="Button" data-sentry-source-file="Newsletter.tsx">
              Product Updates
            </Button>
            <Button variant={hasNewsletter && !isEverythingChecked ? "purple-lighter" : "ghost"} className="text-white" icon={hasNewsletter && !isEverythingChecked ? "check-circle" : undefined} iconPlacement="left" onClick={() => {
            if (isEverythingChecked) {
              setSubscriptions(["newsletter"]);
            } else if (hasNewsletter) {
              setSubscriptions(subscriptions.filter(s => s !== "newsletter"));
            } else {
              setSubscriptions([...subscriptions, "newsletter"]);
            }
          }} data-sentry-element="Button" data-sentry-source-file="Newsletter.tsx">
              News and Tips
            </Button>
          </div>
          <div className="mt-16 w-full max-w-4xl rounded-2xl bg-purple-2 p-2 px-4 md:p-8">
            <FormField control={form.control} name="email" render={({
            field
          }) => <Input className="border-none bg-transparent text-white placeholder:text-beige-2" placeholder="you@email.com" postfix={<Button variant="yellow" rounded className="max-md:hidden" disabled={!form.formState.isValid} onClick={form.handleSubmit(values => signUpNewsletter.mutate({
            email: values.email,
            tags: subscriptions,
            country
          }), defaultFormErrorToast)}>
                      Join our mailing list
                    </Button>} value={field.value} onChange={e => field.onChange(e.target.value)} />} data-sentry-element="FormField" data-sentry-source-file="Newsletter.tsx" />
          </div>
          <Button variant="yellow" rounded className="mt-8 md:hidden" disabled={!form.formState.isValid} onClick={form.handleSubmit(values => signUpNewsletter.mutate({
          email: values.email,
          tags: subscriptions,
          country
        }), defaultFormErrorToast)} data-sentry-element="Button" data-sentry-source-file="Newsletter.tsx">
            Join our mailing list
          </Button>
        </div>
      </div>
    </Form>;
};