import { type Currency } from "@prisma/client";

import { type SupportedLanguage } from "./i18n";

export function toSentenceCase(input: string): string {
  if (!input) return input; // Handle empty string

  // Split the input string into sentences based on periods.
  const sentences = input.split(". ");

  // Capitalize the first letter of each sentence and make the rest lowercase.
  const sentenceCaseSentences = sentences.map((sentence) => {
    // Remove leading and trailing spaces.
    sentence = sentence.trim();

    // Check if the sentence is not empty.
    if (sentence.length > 0) {
      const firstLetter = sentence.charAt(0).toUpperCase();
      const restOfSentence = sentence.slice(1).toLowerCase();
      return firstLetter + restOfSentence;
    } else {
      return sentence; // If the sentence was empty, preserve it as is.
    }
  });

  // Join the sentences back together with periods.
  return sentenceCaseSentences.join(". ");
}

// TODO Merge with /src/utils/reward/i18n-reward.ts
export function toTitleCase(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export function formatPriceIntl(value: number): string {
  return new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}

export const formatPrice = (value: number): string => {
  if (value >= 9999) {
    const abbreviatedValue = (value / 1000).toFixed(1).replace(/\.0$/, "") + "k";
    return `${abbreviatedValue} kr`;
  } else {
    return formatPriceIntl(value);
  }
};

export const renderReward = (lang: SupportedLanguage, currency: Currency, bounty: number) => {
  // https://github.com/nodejs/node/issues/52244
  // avoid hydration error by moving sign to the end
  const value = new Intl.NumberFormat(lang, {
    currency: currency,
    compactDisplay: "long",
    currencyDisplay: "narrowSymbol",
    unitDisplay: "narrow",
    notation: "standard",
    style: "currency",
    minimumFractionDigits: 0,
    localeMatcher: "lookup",
  }).format(bounty);
  if (lang === "no" && currency === "NOK") {
    return `${value.replace("kr", "").trim()} kr`;
  }
  return value;
};

export function stdFormatDate(date: Date, includeTime = false): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (includeTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  return date.toLocaleString("nb-NO", options);
}

export const stripStartEndHtmlTags = (input?: string | null): string => {
  if (!input) return "";
  return input.replace(/^<[^><]*>/, "").replace(/<[^><]*>$/, "");
};
